import React from "react";
import Typography from "../Typography/Typography";
import { AppContext } from "../../AppContext";
import Button from "../Button/Button";
import Image from "../Image/Image";
import { useMutation } from "react-query";
import usePostComment from "../../hooks/usePostComment";
import Alert from "@material-ui/lab/Alert";
import {
  CommentBoxHeader,
  CommentBoxWrap,
  CommentTextArea,
  CommentTextAreaWrap,
} from "./LoanCommentsElements";
import defaultProfilePicture from "../../assets/defaultProfilePicture.png";

export default ({ id, refetchLoanDetail }) => {
  const { currentUser } = React.useContext(AppContext);
  const [comment, setComment] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const [mutatePostComment, { isLoading: isLoadingPostComment }] = useMutation(
    usePostComment,
    {
      onSuccess: (response) => {
        setComment("");
        setSuccessMessage(response.message);
        refetchLoanDetail();

        setTimeout(() => {
          setSuccessMessage("");
        }, [3000]);
      },
    }
  );

  const handleSubmit = () => {
    if (currentUser) {
      mutatePostComment({
        loan_post_id: id,
        comment,
      });
    } else {
      alert("Please login to comment.");
    }
  };

  return (
    <>
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      <CommentBoxHeader>
        <Typography body1Bold>Comments</Typography>
        {/* <Button>Add Comments</Button> */}
      </CommentBoxHeader>
      <CommentBoxWrap>
        {currentUser && (
          <Image
            src={currentUser.image ? currentUser.image : defaultProfilePicture}
            style={{ width: 56, height: 56, borderRadius: 30 }}
          />
        )}
        <CommentTextAreaWrap>
          <CommentTextArea
            rows={6}
            value={comment}
            placeholder="Write a comment ..."
            onChange={(e) => setComment(e.target.value.substring(0, 500))}
          />
          <Button
            // color="#40A747"
            onClick={handleSubmit}
            isLoading={isLoadingPostComment}
            disabled={!comment}
          >
            Submit
          </Button>
        </CommentTextAreaWrap>
      </CommentBoxWrap>
    </>
  );
};
