import React from "react";
import {
  FormLabelWrap,
  RequiredIcon,
  FormInputWrap,
  Input,
} from "./FormElements";

export const FormLabel = ({ children, required, ...otherProps }) => (
  <FormLabelWrap {...otherProps}>
    {children}
    {required && <RequiredIcon>*</RequiredIcon>}
  </FormLabelWrap>
);

export const FormInput = ({ dollarSign, ...otherProps }) => (
  <FormInputWrap dollarSign={dollarSign}>
    {dollarSign && <span style={{ color: "#666666", marginRight: 5 }}>$</span>}
    <Input {...otherProps} />
  </FormInputWrap>
);
