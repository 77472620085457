import React from "react";
import {
  ViewAll,
  Typography,
  ReportDialog,
  SendMessage,
} from "../../../components";
// import {
//   ListTitleWrapper,
//   ListContent,
//   ListItem,
//   DateWrapper,
//   ListItemAvatar,
//   ListItemColumn3,
//   CalendarIcon,
// } from "./homeColumnListElements";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import useLoanPostList from "../../../hooks/useLoanPostList";
import useLoanPostLike from "../../../hooks/useLoanPostLike";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { AiOutlineLike } from "react-icons/ai";
import { FaRegComment } from "react-icons/fa";
import { FiMoreHorizontal, FiSend } from "react-icons/fi";
import { ItemWrap, LikeCommentWrap, MoreBtn } from "./communityElements";
import colors from "../../../constants/colors";

export default () => {
  const history = useHistory();
  const [loanPostList, setLoanPostList] = React.useState([]);
  const [isReportOpen, setIsReportOpen] = React.useState(false);
  const [selectedBoard, setSelectedBoard] = React.useState({
    boardId: null,
    userId: null,
  });
  const [isSendMessageOpen, setIsSendMessageOpen] = React.useState(false);

  const [mutateLoanPostList, { isLoading }] = useMutation(useLoanPostList, {
    onSuccess: (response) => {
      setLoanPostList(
        response.loan_posts.map((d) => ({
          ...d,
          myLike: d.my_like,
        }))
      );
    },
  });

  const [mutateLoanPostLike] = useMutation(useLoanPostLike, {
    onSuccess: () => fetchLoanPostList(),
  });

  React.useEffect(() => fetchLoanPostList(), []);

  const fetchLoanPostList = () => {
    mutateLoanPostList({
      filters: [],
    });
  };

  const handleLike = (id) => {
    setLoanPostList(
      loanPostList.map((d) => ({
        ...d,
        myLike: d.id === id ? !d.myLike : d.myLike,
        total_like:
          d.id === id
            ? d.myLike
              ? d.total_like - 1
              : d.total_like + 1
            : d.total_like,
      }))
    );
    mutateLoanPostLike({
      loan_post_id: id,
    });
  };

  return (
    <div>
      <ReportDialog
        open={isReportOpen}
        close={() => setIsReportOpen()}
        id={selectedBoard.boardId}
      />
      <SendMessage
        boardId={selectedBoard.boardId}
        userId={selectedBoard.userId}
        open={isSendMessageOpen}
        close={() => setIsSendMessageOpen(false)}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div />
        <Typography h2>Community Board</Typography>
        <ViewAll onClick={() => history.push("/communityBoard")} />
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          paddingTop: 20,
        }}
      >
        {isLoading && loanPostList.length === 0 ? (
          <Typography h3>Loading...</Typography>
        ) : (
          loanPostList
            .slice(0, 3)
            .map(
              ({
                id,
                title,
                created_at,
                description,
                image,
                user,
                total_like,
                myLike,
                total_comments,
              }) => (
                <ItemWrap key={id}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push(`/communityBoard/${id}`)}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <img
                          src={user?.image}
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: 30,
                            backgroundColor: "#efefef",
                            objectFit: "contain",
                          }}
                        />
                        <div style={{ marginLeft: 12 }}>
                          <Typography body1>{user?.name}</Typography>
                          <div>
                            <Typography body2>
                              {moment(created_at).format("MMMM DD, YYYY")}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <MoreBtn
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsReportOpen(true);
                          setSelectedBoard({
                            boardId: id,
                            userId: user.id,
                          });
                        }}
                      >
                        <FiMoreHorizontal size={22} color="#555" />
                      </MoreBtn>
                    </div>
                    <div style={{ marginTop: 20, marginBottom: 6 }}>
                      <div
                        style={{
                          height: 26,
                          overflow: "hidden",
                          fontWeight: 500,
                          color: "#555",
                          fontSize: 18,
                        }}
                      >
                        {title}
                      </div>
                      <div
                        style={{
                          height: 42,
                          overflow: "hidden",
                          color: "#999",
                          fontSize: 14,
                        }}
                      >
                        {description && ReactHtmlParser(description)}
                      </div>
                    </div>
                    {image && (
                      <img
                        src={image}
                        style={{
                          height: 180,
                          width: "100%",
                          backgroundColor: "#f1f1f1",
                          margin: "6px 0",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <LikeCommentWrap
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLike(id);
                      }}
                    >
                      <AiOutlineLike
                        size={20}
                        color={myLike ? colors.primary : "#444"}
                      />
                      <div
                        style={{
                          marginLeft: 6,
                          fontSize: 13,
                          color: myLike ? colors.primary : "#666",
                        }}
                      >
                        {total_like ? total_like : null} Like
                      </div>
                    </LikeCommentWrap>
                    <LikeCommentWrap
                      onClick={() => history.push(`/communityBoard/${id}`)}
                    >
                      <FaRegComment size={18} color="#555" />
                      <div
                        style={{ marginLeft: 6, fontSize: 13, color: "#666" }}
                      >
                        {total_comments > 0 && total_comments} Comment
                      </div>
                    </LikeCommentWrap>
                    <LikeCommentWrap
                      onClick={() => {
                        setIsSendMessageOpen(true);
                        setSelectedBoard({
                          boardId: id,
                          userId: user.id,
                        });
                      }}
                    >
                      <FiSend size={18} color="#555" />
                      <div
                        style={{ marginLeft: 6, fontSize: 13, color: "#666" }}
                      >
                        Send Message
                      </div>
                    </LikeCommentWrap>
                  </div>
                </ItemWrap>
              )
            )
        )}
      </div>
    </div>
  );
};
