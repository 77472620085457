import React from "react";
import useSiteSettings from "../../hooks/useSiteSettings";
import { TopBar, TopBarContainer } from "./TopBarElements";

export default () => {
  const { isLoading, data } = useSiteSettings();

  if (isLoading) {
    return null;
  }

  const { contact_primary, email } = data[0];

  return (
    <TopBar>
      <TopBarContainer>
        <a href={`tel:${contact_primary}`}>Call us: {contact_primary}</a>
        &nbsp;&nbsp;
        <a href={`mailto:${email}`}>Email us: {email}</a>
      </TopBarContainer>
    </TopBar>
  );
};
