import styled from "styled-components";
import { Link } from "react-router-dom";
import { MAX_WIDTH } from "../../constants/layout";
import colors from "../../constants/colors";

export const Nav = styled.div`
  display: flex;
  height: 80px;
  justify-content: center;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  max-width: ${MAX_WIDTH};
`;

export const NavLogoLink = styled(Link)`
  cursor: pointer;
`;

export const NavLogo = styled.img`
  height: 50px;
  object-fit: contain;
`;

export const NavMenu = styled.ul`
  display: flex;
  list-style: none;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0 20px;
`;

export const NavLinks = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: #494949;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;

  &:hover {
    text-decoration: underline;
  }
`;

export const NavProfileWrap = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const ProfileDDItemText = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.24px;
`;

export const ProfileDropDownItem = styled.div`
  padding: 10px 20px;
  border-bottom: 0.5px solid #efefef;
  cursor: pointer;
  color: #494949;

  :hover {
    background-color: #494949;
    color: #fff;
  }
`;

export const NavBtnWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  .profile-dropdown-menu {
    display: none;
    position: absolute;
    top: 80px;
    right: 0;
    display: none;
    width: 200px;
    background-color: #fff;
  }

  :hover {
    .profile-dropdown-menu {
      display: block;
    }
  }

  @media screen and (max-width: 900px) {
    .profile-dropdown-menu-wrap {
      display: none;
    }
  }
`;

export const SignInSignOutBtn = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  cursor: pointer;
  color: #494949;
  font-size: 14px;
  text-decoration: none;
  margin: 0 1.2rem;
`;

export const ProfilePicture = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 18px;
  margin-right: 10px;
  background-color: #cfcfcf;
  object-fit: cover;
`;

export const MenuBarsIcon = styled.span`
  color: ${colors.primary};
  font-size: 1.5rem;
  margin-right: 25px;
  cursor: pointer;
  display: none;

  @media screen and (max-width: 900px) {
    display: flex;
  }
`;
