import "./App.css";
import { Route, Switch } from "react-router-dom";

import Home from "./screens/home/home";
import {
  Footer,
  Navbar,
  TopBar,
  ScrollToTop,
  LoanComments,
} from "./components";
import SignIn from "./screens/signIn/signIn";
import SignUp from "./screens/signUp/signUp";
import Blogs from "./screens/blogs/blogs";
import About from "./screens/about/about";
import Associations from "./screens/associations/associations";
import Resources from "./screens/resources/resources";
import BlogDetail from "./screens/blogs/blogDetail/blogDetail";
import News from "./screens/news/news";
import JobBoard from "./screens/jobBoard/jobBoard";
import JobBoardDetail from "./screens/jobBoard/jobBoardDetail/jobBoardDetail";
import Events from "./screens/events/events";
import EventDetail from "./screens/events/eventDetail/eventDetail";
import NewsDetail from "./screens/news/newsDetail/newsDetail";
import HotProperty from "./screens/hotProperty/hotProperty";
import HotPropertyDetail from "./screens/hotProperty/hotPropertyDetail/hotPropertyDetail";
import SubCategoryList from "./screens/listing/subCategoryList";
import HomeSearchList from "./screens/listing/homeSearchList";
import ProfileDetail from "./screens/listing/profileDetail/profileDetail";
import Profile from "./screens/profile/profile";
import ContactUs from "./screens/contactUs/contactUs";
import PrivateRoute from "./PrivateRoute";
import LoanPosts from "./screens/loanPosts/loanPosts";
import PrivacyPolicy from "./screens/privacyPolicy/privacyPolicy";
import TermsOfService from "./screens/termsOfService/termsOfService";
import AllSubCategoryList from "./screens/allSubCategoryList/allSubCategoryList";
import CookiePolicy from "./screens/cookiePolicy/cookiePolicy";
import Disclaimer from "./screens/disclaimer/disclaimer";
import Faq from "./screens/faq/faq";
import PageNotFound from "./screens/404/404";

function App() {
  return (
    <>
      <ScrollToTop />
      <TopBar />
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/signIn" component={SignIn} />
        <Route exact path="/signUp" component={SignUp} />
        <Route exact path="/associations" component={Associations} />
        <Route exact path="/resources" component={Resources} />
        <Route exact path="/blogs" component={Blogs} />
        <Route exact path="/aboutUs" component={About} />
        <Route exact path="/blogs/:blogId" component={BlogDetail} />
        <Route exact path="/news" component={News} />
        <Route exact path="/news/:newsId" component={NewsDetail} />
        <Route exact path="/news/:newsId" component={NewsDetail} />
        <Route exact path="/jobBoard" component={JobBoard} />
        <Route exact path="/jobBoard/:jobBoardId" component={JobBoardDetail} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/events/:eventId" component={EventDetail} />
        <Route exact path="/hotProperty" component={HotProperty} />
        <Route
          exact
          path="/hotProperty/:hotPropertyId"
          component={HotPropertyDetail}
        />
        <Route exact path="/subCategoryList" component={SubCategoryList} />
        <Route exact path="/homeSearchList" component={HomeSearchList} />
        <PrivateRoute exact path="/communityBoard" component={LoanPosts} />
        <Route exact path="/communityBoard/:id" component={LoanComments} />
        <Route exact path="/profileDetail" component={ProfileDetail} />
        <Route
          exact
          path="/subCategoryList/profileDetail"
          component={ProfileDetail}
        />
        <Route exact path="/contactUs" component={ContactUs} />
        <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
        <Route exact path="/termsAndConditions" component={TermsOfService} />
        <Route exact path="/disclaimer" component={Disclaimer} />
        <Route exact path="/cookiePolicy" component={CookiePolicy} />
        <Route exact path="/faq" component={Faq} />
        <Route
          exact
          path="/allSubCategoryList"
          component={AllSubCategoryList}
        />
        <PrivateRoute exact path="/profile/:profileType" component={Profile} />
        <Route component={PageNotFound} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
