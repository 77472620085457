const { REACT_APP_ENV } = process.env;

export const SHORT_DESCRIPTION_LENGTH = 300;

let API_URL;

if (REACT_APP_ENV === "staging") {
  API_URL = "https://bighound.themenepal.info/api/";
} else if (REACT_APP_ENV === "production") {
  API_URL = "https://admin.bighound.com/api/";
}

export { API_URL };
