import styled from "styled-components";

export const NewsImage = styled.div`
  height: 500px;
  background: transparent url(${(props) => props.img}) 0 / cover no-repeat
    padding-box;
  opacity: 1;
  margin-bottom: 10px;

  @media screen and (max-width: 900px) {
    height: 435px;
  }
  @media screen and (max-width: 425px) {
    height: 249px;
  }
`;

export const NewsInfo = styled.div`
  display: inline-flex;
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 14px/27px Poppins;
  letter-spacing: 0px;
  color: #b7b7b7;
  opacity: 1;
  margin-top: 15px;
  margin-bottom: 13px;
  align-items: center;
`;

export const NewsInfoElement = styled.div`
  margin-right: 25px;
  font-size: 0.85rem;
  line-height: 0.85rem;
`;

export const InfoIcon = styled.div`
  /* height: 1.18rem; */
  display: flex;
  margin-right: 8px;
  font-size: 1rem;
`;

export const NewsTitle = styled.div`
  font-weight: medium;
  font-size: 1.2rem;
  color: #494949;
  line-height: 25px;
  margin-bottom: 12px;
`;

export const NewsContent = styled.div`
  color: #494949;
  padding-top: 20px;
  /* margin-bottom: 80px; */

  & img {
    max-width: 90vw;
  }
`;

export const NewsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 720px auto;
  align-items: self-start;
  ::after {
    content: "";
    display: block;
    margin-bottom: 35px;
  }

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

export const SideContent = styled.div`
  padding: 20px 20px 0 20px;

  & > :first-child {
    margin-bottom: 18px;
  }
`;
export const SideItem = styled.div`
  display: grid;
  grid-gap: 5px;

  a {
    text-decoration: none;
    color: inherit;
  }

  a:hover {
    color: #fc7722;
  }
`;

export const SideItemContainer = styled.div`
  > :after {
    content: "";
    display: block;
    border-bottom: 0.5px solid #b7b7b7;
    margin: 14px 0;
  }

  > :last-child:after {
    border-bottom: unset;
  }
`;

export const SideContainer = styled.div`
  border-radius: 4px;
  background: #fff;
  :after {
    content: "";
    display: block;
    padding-bottom: 25px;
  }
`;

export const TopNewsInfo = styled.div`
  display: inline-flex;
  align-items: center;
  letter-spacing: 0px;
  color: #b7b7b7;
`;

export const MainNewsContainer = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;

  @media screen and (max-width: 425px) {
    /* margin: -10px 5px 0px 5px; */
    padding: 0;
    border-radius: 4px;
    opacity: 1;
  }

  ::after {
    content: "";
    display: block;
    padding-bottom: 25px;
  }
`;

export const NewsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;

  @media screen and (max-width: 1100px) {
    padding: 0 10px 10px 10px;
  }
`;
