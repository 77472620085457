import styled from "styled-components";
import { FaSearch } from "react-icons/fa";
import colors from "../../constants/colors";
import { md, sm } from "../../constants/layout";

export const BreadCrumbsWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const TopFilter = styled.div`
  display: flex;
`;

export const FilterWrap = styled.div`
  display: none;
  @media screen and (max-width: ${md}) {
    display: flex;
    padding-bottom: 15px;
    justify-content: flex-end;
  }
`;

export const SelectWrap = styled.div`
  width: 200px;
  padding-left: 5px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${md}) {
    flex-direction: column;
  }
`;

// export const LeftColumn = styled.div`
//   background-color: #f0f8fc;
//   margin-right: 10px;

//   @media screen and (max-width: ${md}) {
//     width: 100%;
//   }
// `;

export const LeftColumn = styled.div`
  width: 33%;

  @media screen and (max-width: ${md}) {
    display: none;
  }
`;

export const LeftColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 28px 25px;
  margin-bottom: 50px;
`;

export const SearchInputWrap = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  background-color: #f2f2f2;
  padding: 0 20px;
  border-radius: 4px;
  align-items: center;
`;

export const SearchInput = styled.input`
  width: 100%;
  background-color: #f2f2f2;
  border: none;
  outline: none;
  border-radius: 4px;
  font-weight: 300;

  ::placeholder {
    color: #b0b0b0;
  }
`;

export const SearchIcon = styled(FaSearch)`
  color: #b7b7b7;
  font-size: 1.3rem;
`;

export const FilterInputWrap = styled.div`
  padding-top: 10px;
`;

export const TagsInputWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
`;

export const Tag = styled.div`
  padding: 5px 15px;
  border-radius: 18px;
  margin: 5px 12px 5px 0;
  cursor: pointer;
  text-align: center;
  background-color: ${({ selected }) =>
    selected ? colors.primary : "#f2f2f2"};
`;

export const FilterButton = styled.button`
  display: flex;
  width: 100%;
  background-color: ${colors.primary};
  color: #fff;
  border: none;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 300;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 50px;
  justify-content: center;
  align-items: center;
  outline: none;
  margin-top: 20px;

  &:hover {
    transition: all 0.2s ease-in-out;
    opacity: 0.8;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  width: 66%;
  flex-direction: column;
  margin-bottom: 50px;

  @media screen and (max-width: ${md}) {
    width: 100%;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: ${({ selected }) =>
    selected && "0px 4px 10px 1px rgba(0, 0, 0, 0.2)"};
  border-top: ${({ selected }) => selected && `3px solid ${colors.primary}`};
`;

export const ListItemHeader = styled.div`
  display: flex;
  margin-bottom: 15px;
  position: relative;
`;

export const ListItemAvatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-color: #efefef;
  object-fit: cover;
`;

export const ListHeaderTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ListItemInfoWrap = styled.div`
  display: flex;
  align-items: center;

  .profile-sms {
    display: none;
  }

  @media screen and (max-width: ${sm}) {
    .profile-sms {
      display: block;
    }
  }
`;

export const ListItemInfo = styled.div`
  display: flex;
  padding-right: 10px;
  align-items: center;
  border-right: ${({ showBorder }) =>
    showBorder ? "1px solid #eaeaea" : "none"};
  margin-right: 10px;
  cursor: pointer;
`;

export const ListItemInfoText = styled.div`
  @media screen and (max-width: ${sm}) {
    display: none;
  }
`;

export const ListItemInfoIcon = styled.span`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: ${({ color }) => color};
  color: #fff;
  margin-right: 10px;
  font-size: 14px;
`;

export const PremierIcon1 = styled.div`
  border-radius: 20px;
  background-color: rgba(252, 119, 34, 0.15);
  border-color: ${colors.primary2};
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 22px;
  color: ${colors.primary2};
  font-size: 14px;
  border: 1px solid ${colors.primary2};
  display: block;
  margin-right: 15px;

  @media screen and (max-width: ${md}) {
    display: none;
  }
`;

export const PremierIcon2 = styled.div`
  border-radius: 20px;
  background-color: rgba(252, 119, 34, 0.15);
  border-color: ${colors.primary2};
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 22px;
  color: ${colors.primary2};
  font-size: 14px;
  border: 1px solid ${colors.primary2};
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: none;

  @media screen and (max-width: ${md}) {
    display: inline-block;
  }
`;

export const FavIcon = styled.span`
  color: ${colors.primary};
  font-size: 22px;
  cursor: pointer;
  margin-top: -2px;
`;
