import React from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  HeroSection,
  BreadCrumbs,
  Typography,
  Button,
  FormInput,
  FormLabel,
} from "../../components";
import {
  Wrapper,
  RegisterWrapper,
  SignUpWrapper,
  // InfoList,
  // InfoItem,
  // InfoIcon,
  RegisterBtn,
  InputListWrap,
  InputWrap,
  ErrorText,
  RememberMe,
  RememberMeChecked,
  RememberMeUnChecked,
  LoginBtn,
  SignUpAgreeLink,
} from "./signUpElements";
import { FaCheck } from "react-icons/fa";
import { useMutation } from "react-query";
import useSignUp from "../../hooks/useSignUp";
import useForm from "../../hooks/useForm";
import validate from "../../utilities/validate";
import formData from "./formData";
import Alert from "@material-ui/lab/Alert";
import useStateList from "../../hooks/useStateList";
import ReactSelect from "react-select";
import ReCAPTCHA from "react-google-recaptcha";

export default () => {
  const history = useHistory();
  const { values, handleChange, setValues } = useForm(formData);
  const [isAgree, setIsAgree] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [successMessage, setSuccessMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState(null);
  const [isRecaptcha, setIsRecaptcha] = React.useState(false);

  const { data: stateList } = useStateList();
  const [mutateSignUp, { isLoading }] = useMutation(useSignUp, {
    onSuccess: (response) => {
      setSuccessMessage(response.data.message);
      resetFormState();
      setFormErrors({});
    },
    onError: (e) => {
      const error = e.response.data.data;
      const errorValues = Object.values(error)[0];

      setErrorMessage(errorValues[0]);
    },
  });

  const resetFormState = () => {
    setValues(
      values.map((d) => ({
        ...d,
        value: "",
      }))
    );
  };

  const toggleAgree = () => setIsAgree(!isAgree);

  const handleChangeSelect = (item) => {
    setValues(
      values.map((d) => ({
        ...d,
        value: d.type === "select" ? item : d.value,
      }))
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors({});

    setSuccessMessage("");
    setErrorMessage("");

    const tempErrors = validate(
      values.map((d) => ({
        ...d,
        value: d.name === "phoneNumber" ? phoneNumber : d.value,
      }))
    );

    if (Object.keys(tempErrors).length > 0) {
      setFormErrors(tempErrors);
    } else if (!isAgree) {
      setErrorMessage("Please read and agree to the terms and conditions.");
    } else if (!isRecaptcha) {
      alert("Please check recaptcha");
    } else {
      const first_name = values.find((d) => d.name === "firstName").value;
      const last_name = values.find((d) => d.name === "lastName").value;
      const address = values.find((d) => d.name === "address").value;
      const job_title = values.find((d) => d.name === "jobTitle").value;
      const company = values.find((d) => d.name === "company").value;
      const email = values.find((d) => d.name === "emailAddress").value;
      const password = values.find((d) => d.name === "password").value;
      const confirm_password = values.find(
        (d) => d.name === "confirmPassword"
      ).value;
      const city = values.find((d) => d.name === "city").value;
      const zip_code = values.find((d) => d.name === "zipCode").value;
      const state_id = values.find((d) => d.name === "state").value.value;

      mutateSignUp({
        first_name,
        last_name,
        address,
        job_title,
        company,
        phone_no: phoneNumber,
        email,
        password,
        confirm_password,
        city,
        zip_code,
        state_id,
      });
    }
  };

  const formInput = ({ name, type, value, placeholder }) => {
    if (type === "select") {
      return (
        <div style={{ marginTop: 6 }}>
          <ReactSelect
            options={
              stateList &&
              stateList.map((d) => ({
                label: d.name,
                value: d.id,
              }))
            }
            value={value}
            onChange={(selectedItem) => handleChangeSelect(selectedItem)}
            isClearable
          />
        </div>
      );
    } else if (type === "tel") {
      return (
        <FormInput
          id={name}
          name={name}
          type={type}
          pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
          placeholder={placeholder}
          value={phoneNumber}
          // onKeyUp={handleKeyDown}
          onChange={(e) => {
            const tempValue = e.target.value.substring(0, 14);

            var cleaned = ("" + tempValue).replace(/\D/g, "");
            const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if (match) {
              const intlCode = match[1] ? "+1 " : "",
                number = [
                  intlCode,
                  "(",
                  match[2],
                  ") ",
                  match[3],
                  "-",
                  match[4],
                ].join("");

              setPhoneNumber(number);
              return;
            }

            setPhoneNumber(tempValue);
          }}
          // required
        />
      );
    } else {
      return (
        <FormInput
          id={name}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
      );
    }
  };

  const handleReCaptcha = (token) => {
    if (token) {
      setIsRecaptcha(true);
    }
  };

  return (
    <>
      <HeroSection
        title="Sign up"
        description="Please login or register your account"
      />
      <Container>
        <BreadCrumbs data={[{ title: "Sign up" }]} />
        <Wrapper>
          {successMessage ? (
            <div
              style={{
                width: "100%",
                backgroundColor: "#fff",
                padding: 50,
                alignItems: "center",
              }}
            >
              <Alert severity="success">{successMessage}</Alert>
              <Button marginTop={10} onClick={() => history.push("/signIn")}>
                Login
              </Button>
            </div>
          ) : (
            <>
              <RegisterWrapper>
                <Typography h1>Welcome to</Typography>
                <Typography h1>Big Hound</Typography>
                {/* <Typography h3 color="#fff" marginTop={15}>
                  You have just some questions to get started
                </Typography> */}
                <RegisterBtn>
                  <Button white onClick={() => history.push("/signIn")}>
                    Login
                  </Button>
                </RegisterBtn>
              </RegisterWrapper>
              <SignUpWrapper>
                <form onSubmit={handleSubmit}>
                  <Typography h2>Sign Up</Typography>
                  <Typography body1>Create an account!</Typography>
                  {successMessage && (
                    <Alert severity="success">{successMessage}</Alert>
                  )}
                  {errorMessage && (
                    <Alert severity="error">{errorMessage}</Alert>
                  )}
                  <InputListWrap>
                    {values.map(
                      ({ name, label, placeholder, type, value, required }) => (
                        <InputWrap key={name}>
                          <FormLabel htmlFor={name} required={required}>
                            {label}
                          </FormLabel>
                          {formInput({
                            name,
                            type,
                            placeholder,
                            value,
                          })}
                          {<ErrorText>{formErrors[name]}</ErrorText>}
                        </InputWrap>
                      )
                    )}
                  </InputListWrap>
                  <RememberMe>
                    {isAgree ? (
                      <RememberMeChecked onClick={toggleAgree}>
                        <FaCheck />
                      </RememberMeChecked>
                    ) : (
                      <RememberMeUnChecked onClick={toggleAgree} />
                    )}
                    <div style={{ flex: 1 }}>
                      <Typography body1>
                        I agree to all the&nbsp;
                        <SignUpAgreeLink
                          to="/termsAndConditions"
                          target="_blank"
                        >
                          Terms & Conditions
                        </SignUpAgreeLink>{" "}
                        and &nbsp;
                        <SignUpAgreeLink to="/privacyPolicy" target="_blank">
                          Privacy Policy
                        </SignUpAgreeLink>
                      </Typography>
                    </div>
                  </RememberMe>
                  <div style={{ marginTop: 16 }}>
                    <ReCAPTCHA
                      sitekey="6LclH4sbAAAAAAsIGTGNY_i3yyHox4zYZg0RBeg3"
                      onChange={handleReCaptcha}
                    />
                  </div>
                  <LoginBtn>
                    <Button type="submit" isLoading={isLoading}>
                      Register
                    </Button>
                  </LoginBtn>
                </form>
              </SignUpWrapper>
            </>
          )}
        </Wrapper>
      </Container>
    </>
  );
};
