import React, { useEffect } from "react";
import {
  Container,
  // Container2,
  SlickSlider,
  Confirm,
} from "../../components";
import Hero from "./hero/hero";
import SocialSearch from "./socialSearch/socialSearch";
// import HomeColumnList from "./homeColumnList/homeColumnList";
import Subscribe from "./subscribe/subscribe";
import useCategories from "../../hooks/useCategories";
import CommunityBoard from "./communityBoard/communityBoard";
import PropertyPicks from "../home/propertyPicks/propertyPicks";

export default () => {
  const { isLoading, data } = useCategories();

  useEffect(() => {
    // This will redirect users to download app screen
    // open in mobile browser
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (!localStorage.getItem("isDownloadApp")) {
      setTimeout(() => {
        if (/android/i.test(userAgent)) {
          localStorage.setItem("isDownloadApp", true);
          if (confirm("Download mobile app?")) {
            window.location.href =
              "https://play.google.com/store/apps/details?id=com.bighound.mobile";
          }
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          localStorage.setItem("isDownloadApp", true);
          if (confirm("Download mobile app?")) {
            window.location.href =
              "https://apps.apple.com/us/app/big-hound/id1548890973";
          }
        }
      }, 4000);
    }
  }, []);

  return (
    <>
      {!isLoading && <Hero categories={data} />}
      <Container>
        {!isLoading && <SlickSlider type1 data={data[0]} marginTop={30} />}
      </Container>
      <Container>
        {!isLoading && <SlickSlider type1 data={data[1]} />}
      </Container>
      {/* <Container2>
        {!isLoading && <SlickSlider type2 data={data[1]} />}
      </Container2> */}
      <Container>
        {!isLoading && <SlickSlider type1 data={data[2]} />}
        {/* <HomeColumnList /> */}
        <CommunityBoard />
        <PropertyPicks />
        <SocialSearch />
        <Subscribe />
      </Container>
    </>
  );
};
