import styled from "styled-components";

export const FormLabelWrap = styled.label`
  color: #494949;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
`;

export const FormInputWrap = styled.div`
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: ${({ dollarSign }) => (dollarSign ? "0 10px" : "0 20px")};
  margin-top: 5px;
`;

export const Input = styled.input`
  width: 100%;
  height: 42px;
  border: none;
  outline: none;
  font-weight: 300;
  background-color: #f2f2f2;
  align-items: center;

  ::placeholder {
    color: #b0b0b0;
  }
`;

export const FormTextArea = styled.textarea`
  width: 100%;
  border: none;
  background-color: #f2f2f2;
  padding: 10px 20px;
  font-weight: 300;
  margin-top: 5px;
`;

export const RequiredIcon = styled.span`
  color: red;
  font-size: 0.8rem;
  margin-left: 5px;
`;

export const FormErrorText = styled.p`
  font-size: 0.7rem;
  color: red;
  font-weight: 300;
  margin-top: 2px;
`;
